import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { logout } from "../../services";
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import {getUserDetails} from '../../services/';
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';

import styles from './styles.module.scss';

import aiIcon from '../../images/ai.png';
import logoDark from "../../images/logo-dark.png";
import logoLight from "../../images/logo-light.png";
import signupIcon from "../../images/signup-icon.png";
import mockup from "../../images/pharma-mockup-1.png";
import waveFooter from "../../images/wave-footer.png";
import benefitPrice from '../../images/benefit-price.png';
import benefitTime from '../../images/benefit-time.png';
import benefitRevenue from '../../images/benefit-revenue.png';
import benefitSaveTime from '../../images/benefit-save-time.png';
import benefitTeam from '../../images/benefit-team.png';
import benefitControl from '../../images/benefit-control.png';
import reviewScreenshot from '../../images/screenshot-2.png';
import reviewStars from '../../images/review-stars.png';
import kieran from '../../images/kieran.jpeg';
import checkMark from '../../images/check-mark.png';
import pricingWarehouse from '../../images/pricing-pharmacy.png';
import companiesBanner from '../../images/companies.png';
import customs from '../../images/transland-mockup.png';
import trustpilot from '../../images/trustpilot.png';
import xButton from '../../images/x-button.png';
import globeIcon from '../../images/global-icon.png';
import crateIcon from '../../images/crate-icon.png';
import locationIcon from '../../images/location-icon.png';
import prescription from '../../images/prescription.png';
import inkel from '../../images/inkel.png';

import award1 from '../../images/award-1.jpeg';
import award2 from '../../images/award-2.png';
import award3 from '../../images/award-3.jpeg';
import award4 from '../../images/award-4.png';
import award5 from '../../images/award-5.jpeg';

import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';

export const Dashboard = () => {
  const [user, setUser] = useState({});
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  const [orderQuantity, setOrderQuantity] = useState(120);
  const [orderAmount,setOrderAmount] = useState(200);
  const ITEMS_PER_PAGE = 25;
  const [menuOpen,setMenuOpen] = useState(false);
  const form = useRef();
  const [loading,setLoading] = useState(false);
  const [length, setLength] = useState(0);
  const [prescriptionText, setPrescriptionText] = useState('65mg Aspirin OD 6/12 \n 5mg Ramipril OD 6/12 \n Dr John Doe')
  const prescriptionCopy = '65mg Aspirin OD 6/12 \n 5mg Ramipril OD 6/12 \n Dr John Doe'
  setTimeout(async()=>{
    (length == prescriptionCopy.length)? await setLength(0):await setLength(length+1);
    setPrescriptionText(prescriptionCopy.substring(0,length).replaceAll('*',"\n"));
  },300)

  const sendMessage = (e)=>{
    e.preventDefault();
    const formData = form.current;
    setLoading(true);

    emailjs.sendForm('service_91jtlma', 'template_hhy6zha', formData, 'h8beUKjFjcEy8tO0m')
      .then((result) => {
            Swal.fire(
          'Message Sent!',
          'We will get back to you within 24 Hours',
          'success'
        )
        document.getElementById("contact-form").style.display = "none";
        setLoading(false);
      }, (error) => {
        Swal.fire(
          'Message Not Sent!',
          'Please Try Again',
          'error'
        )
        console.log(error);
        setLoading(false);
      });

  }

  const renderForm = ()=>{
    if(loading){
      return(
        <div style={{textAlign:"center",alignItems:"center"}}>
          <br/><br/>
          <GridLoader  size={30} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <form ref={form} onSubmit={sendMessage}>
      <input name={"name"} placeholder={"Your Name*"} type="text" className={styles.contactInput} value={contactName} onChange={(event)=>{setContactName(event.target.value)}} />
      <input name={"email"} placeholder={"Your Email*"} type="text" className={styles.contactInput} value={contactEmail} onChange={(event)=>{setContactEmail(event.target.value)}} />
      <textarea name={"message"} placeholder={"How can we help?"} type="text" className={styles.contactInputMessage} value={contactMessage} onChange={(event)=>{setContactMessage(event.target.value)}} />
      <br/><br/>
      <div onClick={sendMessage} className={styles.submitButtonBlue}>
        Send Message
        <img alt={"A signup icon in another signup button."} src={signupIcon} className={styles.submitButtonBlueIcon} />
      </div>
      </form>
    )
  }


    const renderMenu = ()=>{
      if(menuOpen){
        return(
          <div className={styles.mobileMenu}>
            <img src={xButton} className={styles.menuXButton} onClick={()=>{setMenuOpen(false)}} />
            <div className={styles.menuItems}>
              <a style={{ textDecoration: 'none' }} href={'./how-it-works'}>
                <div className={styles.menuItem}>
                  Our Portfolio
                </div>
              </a>
              <a style={{ textDecoration: 'none' }} href={'./about-us'}>
                <div className={styles.menuItem}>
                  About Us
                </div>
              </a>
              <a style={{ textDecoration: 'none' }} href={'./contact-us'}>
                <div className={styles.menuItem}>
                  Contact Us
                </div>
              </a>


              <br/>
              <a style={{ textDecoration: 'none' }} href={'./contact-us'}>
              <div className={styles.signupButtonBlue}>
                Book A Demo
                <img alt={"Signup button icon."} src={signupIcon} className={styles.signupButtonBlueIcon} />
              </div>
              </a>
            </div>
          </div>
        )
      }
    }

    return(
      <div style={{position:"absolute",top:0,left:0,width:"100%",overflowX:"hidden"}}>
        <div id="homepage-header" className={styles.header}>
          <img alt={"The PharmaPro application logo in png format."} src={logoDark} className={styles.headerLogo} />

          <div className={styles.headerMenu}>
            <a style={{ textDecoration: 'none' }} href={'./how-it-works'}>
              <div className={styles.headerMenuItem}>
                Our Portfolio
              </div>
            </a>
            <a style={{ textDecoration: 'none' }} href={'./demos'}>
              <div className={styles.headerMenuItem}>
                Demos
              </div>
            </a>
            <a style={{ textDecoration: 'none' }} href={'./about-us'}>
              <div className={styles.headerMenuItem}>
                About Us
              </div>
            </a>

            <a style={{ textDecoration: 'none' }} href={'./contact-us'}>
              <div className={styles.headerMenuItem}>
                Contact Us
              </div>
            </a>
          </div>

          <div className={styles.headerButtons}>
            <a style={{ textDecoration: 'none' }} href="./contact-us">
              <div className={styles.headerButtonBlue}>
                Learn More
                <img alt={"An icon in the Learn More button for our platform."} src={signupIcon} className={styles.signupButtonIcon} />
              </div>
            </a>

            <div onClick={()=>{setMenuOpen(true)}} className={styles.headerButtonBlueMenu}>
              Menu
              <img alt={"An icon in the Learn More button for our platform."} src={signupIcon} className={styles.signupButtonIcon} />
            </div>
          </div>
        </div>

        <div className={styles.landingContainer}>
          <div className={styles.landingText}>
            <div className={styles.landingTextContent}>
              <h1 className={styles.landingTextHeader}>World-Class App & AI Development.</h1>
              <h2 className={styles.landingTextContentH}>Develop Your Software From Concept To Launch And Beyond.</h2>
              <h3 className={styles.landingTextContentH3}>From Concept Development, UI/ UX, Frontend, Backend and Optimization, We Can Help.</h3>
              <a style={{ textDecoration: 'none' }} href={'./contact-us'}>
              <div className={styles.signupButtonBlue}>
                Get Case Study Demos
                <img alt={"Signup button icon."} src={signupIcon} className={styles.signupButtonBlueIcon} />
              </div>
              </a>
            </div>
          </div>
          <Zoom>
          <div className={styles.landingImageContainer}>
            <img alt={"PharmaPro mockup image giving an overview of the PharmaPro product."} src={mockup} className={styles.landingImage} />
          </div>
          </Zoom>
        </div>
        <br/><br/>
        <img alt={"Wave break image to seperate divs."} src={waveFooter} className={styles.waveFooter} />

        <div className={styles.customsContainer}>
            <div className={styles.pricingText2}>
              <div className={styles.pricingTextHeader}>Case Study: Inkel Health</div>
              <div className={styles.pricingTextSubheader}>Mental Health Coaching Platform</div><br/><br/>
              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                We built a state of the art web app for Inkel Health.
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Coaches can track patient progress, accept payments, create custom content and more.
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                A secure NodeJS backend and NoSQL database were used for managing user information.
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Custom algorithms were designed for customising content for users and their goals.
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                An administrative system was put in place to oversee interactions on the platform.
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Subscriptions were set up to facilitate seamless monthly transactions for coaches.
              </div>

              <br/>
              <a style={{ textDecoration: 'none' }} href="./contact-us">
              <div onClick={(event)=>{document.location = './contact-us'}} style={{marginLeft:"2vw"}} className={styles.signupButtonBlue}>
                Get In Touch
                <img alt={"A signup icon in the signup button."} src={signupIcon} className={styles.signupButtonBlueIcon} />
              </div>
              </a>
          </div>
          <Zoom>
          <div className={styles.landingImageContainer}>
            <img alt={"An image displaying potential forms a company can have to accept files from bookers."} src={inkel} className={styles.landingImageInkel} />
          </div>
          </Zoom>
        </div>

        <img alt={"Wave break image to seperate divs."} src={waveFooter} className={styles.waveFooter} />

        <div className={styles.benefitsContainer}>

          <div className={styles.benefitsTitle}>Why Work With Us?</div>
          <div className={styles.benefit}>
            <img alt={"An icon beside the benefits of using PharmaPro for accepting requests for your business."} src={benefitPrice} className={styles.benefitIcon} />
            <div className={styles.benefitBar} />
            <div className={styles.benefitTitle}>Cost Transparency</div>
            <div className={styles.benefitText}>
            We provide accurate estimates on the overall cost of your project. From our weekly rate and estimated timeframe, to calculating your hosting and maintenance costs going forward.</div>
          </div>

          <div className={styles.benefit}>
            <img alt={"An icon beside the benefits of using PharmaPro for accepting requests for your business."} src={benefitSaveTime} className={styles.benefitIcon} />
            <div className={styles.benefitBar} />
            <div className={styles.benefitTitle}>Operations Efficiency</div>
            <div className={styles.benefitText}>
            Through open communication throughout projects, we can assure you that the finished product will align with your goals.</div>
          </div>

          <div className={styles.benefit}>
            <img alt={"An icon beside the benefits of using PharmaPro for accepting requests for your business."} src={benefitTeam} className={styles.benefitIcon} />
            <div className={styles.benefitBar} />
            <div className={styles.benefitTitle}>Experience</div>
            <div className={styles.benefitText}>
              Becuase of our broad experience in dealing with both startups.
            </div>
          </div>

          <div className={styles.benefit}>
            <img alt={"An icon beside the benefits of using PharmaPro for accepting requests for your business."} src={benefitTime} className={styles.benefitIcon} />
            <div className={styles.benefitBar} />
            <div className={styles.benefitTitle}>Save Time</div>
            <div className={styles.benefitText}>
              Through efficient development practices, we can develop rapidly, without sacrificing quality.
            </div>
          </div>

          <div className={styles.benefit}>
            <img alt={"An icon beside the benefits of using PharmaPro for accepting requests for your business."} src={benefitRevenue} className={styles.benefitIcon} />
            <div className={styles.benefitBar} />
            <div className={styles.benefitTitle}>Drive Revenue Growth</div>
            <div className={styles.benefitText}>
              Through an optimised UI/ UX and onboarding process, you can greatly increase your user retention and satisfaction, giving your software an edge.
            </div>
          </div>

          <div className={styles.benefit}>
            <img alt={"An icon beside the benefits of using PharmaPro for accepting requests for your business."} src={benefitControl} className={styles.benefitIcon} />
            <div className={styles.benefitBar} />
            <div className={styles.benefitTitle}>Full Control</div>
            <div className={styles.benefitText}>
              Your software, code and user data is all yours, no recurring fees from us beyond maintenance.
            </div>
          </div>
        </div>

        <img alt={"A wave image to seperate content."} src={waveFooter} className={styles.waveFooter} />

        <div className={styles.awards}>
          <div className={styles.awardsTitle}>Awards We've Won</div>
          <div className={styles.awardsContent}>
            <img className={styles.award} src={award1} />
            <img className={styles.award} src={award2} />
            <img className={styles.award} src={award3} />
            <img className={styles.award} src={award4} />
            <img className={styles.award} src={award5} />
          </div>
        </div>

        <img alt={"A wave image to seperate content."} src={waveFooter} className={styles.waveFooter} />

        <div className={styles.customsContainer}>
          <div className={styles.landingText2}>
            <div className={styles.landingTextContent2}>
              <div className={styles.landingTextHeader2}>Case Study: Transland Group, International Logistics Enterprise</div>
              We helped Transland Group build an advanced ordering and backend management system that can scale with their growing customer base.<br/>
              The adaptable system can handle large payments and facilitates dynamic pricing algorithms.<br/>
              <a style={{ textDecoration: 'none' }} href="./contact-us">
              <div className={styles.signupButtonBlue}>
                Get An Evaluation
                <img alt={"An icon on the signup button."} src={signupIcon} className={styles.signupButtonBlueIcon} />
              </div>
              </a>
            </div>
          </div>
          <Zoom>
          <div className={styles.landingImageContainer}>
            <img alt={"An image displaying potential forms a company can have to accept files from bookers."} src={customs} className={styles.landingImageTransland} />
          </div>
          </Zoom>
        </div>

        <div className={styles.reviewContainer}>
          <div className={styles.review}>
            <Zoom>
              <img alt={"A screenshot of the PharmaPro web application beside a positive review of the platform."} src={reviewScreenshot} className={styles.reviewScreenshot} />
            </Zoom>
            <div className={styles.reviewContent}>
              <div className={styles.reviewText}>
                <img alt={"A image display of five stars above a review of PharmaPro."} src={reviewStars} className={styles.reviewStars} /><br/>
                Gareth & Conquer helped redevelop our enitre system and continue serving our customers in a secure and efficient manner online.<br/>
                <img alt={"A profile image of someone who has reviewed PharmaPro."} src={kieran} className={styles.reviewImage}/>
                <div className={styles.reviewSender}>Fiona Williams, Transland Group</div>
              </div>
            </div>
          </div>
        </div>

        <Bounce right>
        <div className={styles.pricingContainer}>
          <div className={styles.pricingText}>
            <div className={styles.pricingTextHeader}>Our Tech Stack</div>
            <div className={styles.pricingTextSubheader}>How We Build Our Apps</div><br/><br/>
            <div className={styles.pricingPoint}>
              <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
              ReactJS for Frontend Web Apps
            </div>

            <div className={styles.pricingPoint}>
              <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
              NodeJS, Python & PHP for Backend Development
            </div>

            <div className={styles.pricingPoint}>
              <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
              MySQL, NoSQL & PostgresSQL Databases
            </div>

            <div className={styles.pricingPoint}>
              <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
              Tensorflow, Keras, OpenAI and more for AI
            </div>

            <div className={styles.pricingPoint}>
              <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
              ReactNative, Swift & Kotlin For Mobile
            </div>

            <div className={styles.pricingPoint}>
              <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
              Github & Circle CI For DevOps
            </div>

            <br/>
            <a style={{ textDecoration: 'none' }} href="./contact-us">
            <div onClick={(event)=>{document.location = './contact-us'}} style={{marginLeft:"2vw"}} className={styles.signupButtonBlue}>
              Get In Touch
              <img alt={"A signup icon in the signup button."} src={signupIcon} className={styles.signupButtonBlueIcon} />
            </div>
            </a>
          </div>
          <div className={styles.pricingImageContainer}>
            <img alt={"An image of a man in a freight warehouse using PharmaPro to accept transport requests in his business."} src={pricingWarehouse} className={styles.pricingImage} />
          </div>
        </div>
        </Bounce>

        <div className={styles.figuresContainer}>
          <div className={styles.figure}>
            <div className={styles.figureHeader}>26%</div>
            Average Increase In Revenue
          </div>

          <div className={styles.figure}>
            <div className={styles.figureHeader}>35%</div>
            Higher User Rates
          </div>

          <div className={styles.figure}>
            <div className={styles.figureHeader}>30+</div>
            Mobile & Web Apps Developed
          </div>

          <div className={styles.figure}>
            <div className={styles.figureHeader}>100%</div>
            Client Satisfaction
          </div>
        </div>

        <div className={styles.contactContainer}>
          <div id={"contact-form"} className={styles.contactForm}>
            <div className={styles.contactHeader}>Want To Get An Evaluation?</div>
            <div className={styles.contactSubheader}>Get In Touch With Us</div>
            {renderForm()}
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.footerPortion}>
            <img alt={"The PharmaPro platform logo as a PNG image."} src={logoDark} className={styles.footerLogo} />
          </div>
          <div className={styles.footerPortion}>
          </div>
          <div className={styles.footerPortion}>
            <a style={{ textDecoration: 'none' }} href="./how-it-works"><div className={styles.footerLink}>How We Work</div></a>
            <a style={{ textDecoration: 'none' }} href="./about-us"><div className={styles.footerLink}>About Us</div></a>
            <a style={{ textDecoration: 'none' }} href="./contact-us"><div className={styles.footerLink}>Contact Us</div></a>
          </div>
          <div className={styles.footerPortionFlex}>
            <div className={styles.flexPadding}></div>
            <div className={styles.footerCopyright}>&copy; Conquer Technologies Ltd. 2023</div>
          </div>
        </div>

        {renderMenu()}
      </div>
    )


}
