import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { logout } from "../../services";
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import {Button} from '../../components/Button/';
import {getUserDetails} from '../../services/';
import Swal from 'sweetalert2';
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import emailjs from '@emailjs/browser';

import styles from './styles.module.scss';

import logoDark from "../../images/logo-dark.png";
import signupIcon from "../../images/signup-icon.png";
import mockup from "../../images/mockup3.png";
import waveFooter from "../../images/wave-footer.png";
import benefitPrice from '../../images/benefit-price.png';
import benefitTime from '../../images/benefit-time.png';
import benefitRevenue from '../../images/benefit-revenue.png';
import benefitSaveTime from '../../images/benefit-save-time.png';
import benefitTeam from '../../images/benefit-team.png';
import benefitControl from '../../images/benefit-control.png';
import reviewScreenshot from '../../images/screenshot1.png';
import reviewStars from '../../images/review-stars.png';
import kieran from '../../images/kieran.jpg';
import checkMark from '../../images/check-mark.png';
import pricingWarehouse from '../../images/pricing-warehouse.png';
import aboutImages from '../../images/about-images.png';
import xButton from '../../images/x-button.png';
import globeIcon from '../../images/global-icon.png';
import crateIcon from '../../images/crate-icon.png';
import locationIcon from '../../images/location-icon.png';
import customs from '../../images/transland-mockup.png';
import recruit from '../../images/recruit.png';
import xtrails from '../../images/xtrails.png';
import socialjam from '../../images/socialjam.png';
import pharmapro from '../../images/pharmapro.png';
import medlaw from '../../images/medlaw.png';
import unbanx from '../../images/unbanx.png';

import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';

const MENU_TYPES = [
  'German',
  'French',
  'Indian',
  'Mexican'
]

export const Demos = () => {
  const [user, setUser] = useState({});
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  const [recordTime, setRecordTime] = useState(0)
  const [suggestions, setSuggestions] = useState([])
  const [generating, setGenerating] = useState(false)
  const [query, setQuery] = useState(MENU_TYPES[0])
  const ITEMS_PER_PAGE = 25;
  const [menuOpen,setMenuOpen] = useState(false);
  const socket = useRef(null)
  const recorder = useRef(null)
  const [isRecording, setIsRecording] = useState(false)
  const [encryption, setEncryption] = useState('')
  const [decryption, setDecryption] = useState('')
  const [decryptionDelay, setDecryptionDelay] = useState('')
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const getMinutes = (time)=>{
    let hours = Math.floor(time / 3600)
    let minutes = Math.floor((time - (hours*3600)) / 60)
    let seconds = Math.floor(time - (hours*3600) - (minutes*60))

    return hours+"h "+minutes+"m "+seconds+"s"
  }

  let recordLoop;

  useEffect(() => {
      function startTimer() {
        recordLoop = setInterval(() => {
          if(isRecording) {
            setRecordTime(recordTime+0.5)
          }
        }, 500);
      };

      startTimer();
      return () => clearInterval(recordLoop); // cleanup
    }, [isRecording, recordTime, setRecordTime]);



    const renderMenu = ()=>{
      if(menuOpen){
        return(
          <div className={styles.mobileMenu}>
            <img src={xButton} className={styles.menuXButton} onClick={()=>{setMenuOpen(false)}} />
            <div className={styles.menuItems}>
              <a style={{ textDecoration: 'none' }} href={'./how-it-works'}>
                <div className={styles.menuItem}>
                  Our Portfolio
                </div>
              </a>
              <a style={{ textDecoration: 'none' }} href={'./demos'}>
                <div className={styles.menuItem}>
                  Demos
                </div>
              </a>
              <a style={{ textDecoration: 'none' }} href={'./about-us'}>
                <div className={styles.menuItem}>
                  About Us
                </div>
              </a>
              <a style={{ textDecoration: 'none' }} href={'./contact-us'}>
                <div className={styles.menuItem}>
                  Contact Us
                </div>
              </a>

              <br/>
              <a style={{ textDecoration: 'none' }} href={'./contact-us'}>
              <div className={styles.signupButtonBlue}>
                Book A Call
                <img alt={"Signup button icon."} src={signupIcon} className={styles.signupButtonBlueIcon} />
              </div>
              </a>
            </div>
          </div>
        )
      }
    }

    return(
      <div style={{position:"absolute",top:0,left:0}}>
        <div id="homepage-header" className={styles.header}>
          <a style={{ textDecoration: 'none' }} href={'../'}>
            <img alt={"FreightCart system logo including a parcel icon and the name of the system."} src={logoDark} className={styles.headerLogo} />
          </a>

          <div className={styles.headerMenu}>
            <a style={{ textDecoration: 'none' }} href={'./how-it-works'}>
              <div className={styles.headerMenuItem}>
                Our Portfolio
              </div>
            </a>
            <a style={{ textDecoration: 'none' }} href={'./how-it-works'}>
              <div style={{color:"#0a68ff"}} className={styles.headerMenuItem}>
                Demos
              </div>
            </a>

            <a style={{ textDecoration: 'none' }} href={'./about-us'}>
              <div className={styles.headerMenuItem}>
                About Us
              </div>
            </a>
            <a style={{ textDecoration: 'none' }} href={'./contact-us'}>
              <div className={styles.headerMenuItem}>
                Contact Us
              </div>
            </a>
          </div>

          <div className={styles.headerButtons}>

            <a style={{ textDecoration: 'none' }} href="./contact-us">
            <div className={styles.headerButtonBlue}>
              Get An Estimate
              <img alt={"FreightCart platform icon beside a signup button."} src={signupIcon} className={styles.signupButtonIcon} />
            </div>
            </a>

            <div onClick={()=>{setMenuOpen(true)}} className={styles.headerButtonBlueMenu}>
              Menu
              <img alt={"An icon in the Get A Demo button for our platform."} src={signupIcon} className={styles.signupButtonIcon} />
            </div>
          </div>
        </div>

        <div className={styles.landingContainer}>
          <div className={styles.landingText}>
            <div className={styles.landingTextContent}>
              <h1 className={styles.landingTextHeader}>View Our Technology Demos</h1>
              <h2 className={styles.landingTextContentH}>
              View some of our demos below to see live samples of the AI, Audio, Computer Vision and other technologies we have worked on.
              <br/>Get in touch to see more advanced demos and to see how we can develop for your requirements.
              <br/>
              You can also see real-world projects in our portfolio.</h2>
              <a style={{ textDecoration: 'none' }} href="./contact-us">
                <div className={styles.signupButtonBlue}>
                  Get In Touch
                  <img alt={"Signup icon in the Get In Touch Button."} src={signupIcon} className={styles.signupButtonBlueIcon} />
                </div>
              </a>
            </div>
          </div>
        </div>

        <img alt={"Wave image to break up content."} src={waveFooter} className={styles.waveFooter} />

        <div className={styles.demoContainer}>
          <div className={styles.demoTitle}>Writing Recognition Technology</div>
          <div className={styles.demoSubtitle}>We have developed several applications for digit and alphanumeric recognition / OCR.</div>
          <iframe className={styles.demoBox} style={{width:'70%', border:'0px solid red', height:'35vw'}}  src={'recognition/index.html'} />
        </div>

        <img alt={"Wave image to break up content."} src={waveFooter} className={styles.waveFooter} />

        <div className={styles.demoContainer}>
          <div className={styles.demoTitle}>Speech-To-Text Technology</div>
          <div className={styles.demoSubtitle}>Using AI / The Browser Audio API To Convert Speech To Text</div>
          <div className={styles.audioContent}>
            <Button name={(isRecording)?'Stop Recording':'Record'} loading={false} onClick={(e)=> {
              if(isRecording){
                SpeechRecognition.stopListening()
                setIsRecording(false)
                //setRecordTime(0)
                //endTranscription()
              }else{
                setIsRecording(true)
                SpeechRecognition.startListening({ continuous: true })
                //generateTranscript()
              }
            }}/>
            <br/>
            {(recordTime > 0)?('Recording Time: '+getMinutes(recordTime)):''}
            <br/><br/>
            Transcript: {transcript}
          </div>
        </div>

        <img alt={"Wave image to break up content."} src={waveFooter} className={styles.waveFooter} />

        <div className={styles.demoContainer}>
          <div className={styles.demoTitle}>Generative AI Technology</div>
          <div className={styles.demoSubtitle}>Choose A Cuisine Type To Generate A Menu With AI.</div>
          <div className={styles.audioContent}>
            <TextInput type={'dropdown'} options={MENU_TYPES} initialValue={MENU_TYPES[0]} onChange={(v)=>{setSuggestions([]);setQuery(v)}} /><br/>
            <Button name={(generating)?'Generating...':'Generate'} loading={false} onClick={async(e)=> {
              if(generating) return
              await setGenerating(true)
              const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ query })
              }

              fetch('https://xenon-chain-387522.ew.r.appspot.com/honeyjar/getMenuSuggestions', requestOptions)
                  .then(response => response.json())
                  .then(data => {
                    let dataArray = []
                    console.log(data)
                    let response = data.response[0]['message']['tool_calls']
                    for(let i = 0;i < response.length;i++){
                      dataArray.push(JSON.parse(response[i]['function']['arguments'])['menuItems'][0])
                    }

                    setSuggestions(dataArray)
                    setGenerating(false)
                  });
            }}/>
            <br/><br/>
            {(suggestions.length === 0)?(''):('Here are some '+query+' menu item suggestions:')}
            <br/>
            {
              suggestions.map((s)=>{
                return(
                  <div className={styles.suggestion}>
                    <div className={styles.suggestionName}>{s.name}</div>
                    <div className={styles.suggestionDescription}>{s.shortDescrption}</div>
                  </div>
                )
              })
            }
          </div>
        </div>

        <img alt={"Wave image to break up content."} src={waveFooter} className={styles.waveFooter} />

        <div className={styles.demoContainer}>
          <div className={styles.demoTitle}>Encryption Technology</div>
          <div className={styles.demoSubtitle}>We use different encryption technologies for securing 3rd party hooks and user data.</div>
          <div className={styles.audioContent}>
            <TextInput type={'form-text'} onChange={(e)=>{setDecryption(e.target.value)}} /><br/>
            <Button name={'Encrypt'} loading={false} onClick={async(e)=> {
              const nonce = 'gjafwmkrfmkrfm';
              const path = '/'
              const hashDigest = sha256(nonce + decryption);
              const privateKey ='afkd86dkfkg';
              const hmacDigest = Base64.stringify(hmacSHA512(path + hashDigest, privateKey));
              setEncryption(hmacDigest)
              setDecryptionDelay(decryption)
            }}/>
            <br/><br/>
            Encrypted Input: {encryption}<br/><br/>
            Decrypted Input: {decryptionDelay}
          </div>
        </div>

        <div className={styles.figuresContainer}>
          <div className={styles.figure}>
            <div className={styles.figureHeader}>26%</div>
            Average Increase In Revenue
          </div>

          <div className={styles.figure}>
            <div className={styles.figureHeader}>35%</div>
            Higher User Rates
          </div>

          <div className={styles.figure}>
            <div className={styles.figureHeader}>30+</div>
            Mobile & Web Apps Developed
          </div>

          <div className={styles.figure}>
            <div className={styles.figureHeader}>100%</div>
            Client Satisfaction
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.footerPortion}>
            <img alt={"FreightCart platform logo including a parcel icon and the name of the platform."} src={logoDark} className={styles.footerLogo} />
          </div>
          <div className={styles.footerPortion}>
          </div>
          <div className={styles.footerPortion}>
            <a style={{ textDecoration: 'none' }} href="./how-it-works"><div className={styles.footerLink}>Our Portfolio</div></a>
            <a style={{ textDecoration: 'none' }} href="./about-us"><div className={styles.footerLink}>About Us</div></a>
            <a style={{ textDecoration: 'none' }} href="./contact-us"><div className={styles.footerLink}>Contact Us</div></a>
          </div>
          <div className={styles.footerPortionFlex}>
            <div className={styles.flexPadding}></div>
            <div className={styles.footerCopyright}>&copy; Conquer Technologies Ltd. 2023</div>
          </div>
        </div>
        {renderMenu()}
      </div>
    )


}
