import { useRoutes } from "react-router-dom";
import { Dashboard } from "../views/dashboard";
import { HowItWorks } from "../views/howItWorks";
import { FAQs } from "../views/faqs";
import { Contact } from "../views/contact";
import { Blog } from "../views/blog";
import { Article } from "../views/article";
import { LocalCouriers } from "../views/localCouriers";
import { GlobalFreight } from "../views/globalFreight";
import { NationalTransporters } from "../views/nationalTransporters";
import { Demos } from '../views/demos';
//import { NotFound } from "../views/notFound";

export const Routes = () => {
  const element = useRoutes(routes);
  return element;
};

const routes = [
   {
    element: <Dashboard />,
    path: "/",
  },
  {
   element: <HowItWorks />,
   path: "/how-it-works",
 },
 {
  element: <FAQs />,
  path: "/about-us",
  },
  {
   element: <Contact />,
   path: "/contact-us",
  },
  {
   element: <Blog />,
   path: "/blog",
 },{
  element: <Demos />,
  path: "/demos",
},
 {
  element: <Article />,
  path: "/article/:articleId",
  },
];
