import {initializeApp} from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC6ylZLQ2a6pSkUEWs8uW8-RPCCWfWA3-w",
  authDomain: "freightcartblog.firebaseapp.com",
  projectId: "freightcartblog",
  storageBucket: "freightcartblog.appspot.com",
  messagingSenderId: "590384600585",
  appId: "1:590384600585:web:e19de2d22757c476af8ad8"
};

const firebase = initializeApp(firebaseConfig);

const db = getFirestore(firebase);

export { firebase, db };
