import {useState} from 'react';

// Styles
import styles from './index.module.scss';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { GridLoader } from "react-spinners";
import { withAlert } from 'react-alert';
import Cookies from 'universal-cookie';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Views

//Services & Local Logic

// Assets

// Animations
import Fade from 'react-reveal/Fade';

export const TextInput = ({type,placeholder,onChange,onClick,icon,options,initialValue,border,large})=>{

  const [value,setValue] = useState(initialValue || '');

  switch(type){
    case('password'):
      return(
        <input type="password" className={styles.textInput} value={value} onChange={(event)=>{setValue(event.target.value); onChange(event.target.value)}} placeholder={placeholder} />
      )
    break;

    case('email'):
      return(
        <input type="text" className={styles.textInput} value={value} onChange={(event)=>{setValue(event.target.value); onChange(event.target.value)}} placeholder={placeholder} />
      )
    break;

    case('date'):
      return(
        <span className={styles.customInputDateSpan}>
          <span className={styles.customInputDateTitle}>{placeholder}</span>
          <DatePicker onChange={(event)=>{setValue(event); onChange(event)}} selected={value} className={styles.customInputDate} dateFormat="dd/MM/yyyy" calendarClassName="rasta-stripes" />
        </span>
      )
    break;

    case('filter-text'):
      return(
        <span className={styles.customInputFilterSpan}>
          <div className={styles.customInputDateTitle}>{placeholder}</div>
          <input type="text" onChange={(event)=>{setValue(event.target.value); onChange(event.target.value)}} placeholder={placeholder} className={styles.customInputDate}   />
        </span>
      )
    break;

    case('submit-input'):

      if(large){
        return(
          <div style={{border:(border)?"1px solid white":"0px solid white"}} className={styles.submitInputLarge} onClick={(event)=>{onClick(event)}}>
            <img src={icon} className={styles.submitInputIcon} />
            {placeholder}
          </div>
        )
      }else{
        return(
          <div style={{border:(border)?"1px solid white":"0px solid white"}} className={styles.submitInput} onClick={(event)=>{onClick(event)}}>
            <img src={icon} className={styles.submitInputIcon} />
            {placeholder}
          </div>
        )
      }
    break;

    case('submit-input-purple'):
    if(large){
      return(
        <div style={{border:(border)?"1px solid white":"0px solid white"}} className={styles.submitInputLargePurple} onClick={(event)=>{onClick(event)}}>
          <img src={icon} className={styles.submitInputIcon} />
          {placeholder}
        </div>
      )
    }
      else{
        return(
          <div className={styles.submitInputPurple} onClick={(event)=>{onClick(event)}}>
            <img src={icon} className={styles.submitInputIcon} />
            {placeholder}
          </div>
        )
      }
    break;

    case('form-text'):
      return(
        <span className={styles.formInputSpan}>
          <div className={styles.formInputTitle}>{placeholder}</div>
          <input type="text" className={styles.formInput} value={value} onChange={(event)=>{setValue(event.target.value); onChange(event)}} placeholder={placeholder} />
        </span>
      )
    break;

    case('form-number'):
      return(
        <span className={styles.formInputSpan}>
          <div className={styles.formInputTitle}>{placeholder}</div>
          <input type="number" className={styles.formInput} value={value} onChange={(event)=>{setValue(event.target.value); onChange(event)}} placeholder={placeholder} />
        </span>
      )
    break;

    case('dropdown'):
      return(
        <span className={styles.dropdownInputSpan}>
          <div className={styles.formInputTitle}>{placeholder}</div>
          <select  value={value} onChange={(event)=>{setValue(event.target.value); onChange(event.target.value)}}  className={styles.dropdownInput}>
            <option disabled={false} key="Please Select" value={'NO_SELECTION'}>Please Select</option>
            {
              options.map((option)=> <option key={option} value={option}>{option}</option>)
            }
          </select>
        </span>
      )
    break;

    default:
      return(
        <input type="text" className={styles.textInput} value={value} onChange={(event)=>{setValue(event.target.value); onChange(event.target.value)}} placeholder={placeholder} />
      )
    break;
  }

}
