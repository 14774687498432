import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import styles from './styles.module.scss';
import { GridLoader } from "react-spinners";

export const Button = ({id, margin, customContainerStyle, name, onClick, loading, background, color, type, icon}) => {

  if(type == 'plus-square'){
    return (
      <div onClick={(e)=>{if(!loading) onClick()}} className={styles.squareButtonContainer} style={customContainerStyle}>
        <img src={require('../../images/plus-icon.png')} className={styles.plusIcon} />
      </div>
    )
  }


  return (
    <div onClick={(e)=>{if(!loading) onClick()}} className={styles.buttonContainer} style={customContainerStyle}>
        <div className={styles.buttonInputStyled}>
          <div className={styles.buttonInputTextSection} style={{color:(color || '#ffffff'), background:(background || '#6026ff')}}>
            {(loading)?<GridLoader size={3} color={'#FFFFFF'} cssOverride={{verticalAlign: 'middle',marginRight: '6px'}} />:<></>}
            {(icon)?<img className={styles.buttonIcon} src={icon}></img>:<></>}
            {name}
          </div>
        </div>
    </div>
  )

}
