import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { logout } from "../../services";
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import {getUserDetails} from '../../services/';
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';

import styles from './styles.module.scss';

import logoDark from "../../images/logo-dark.png";
import signupIcon from "../../images/signup-icon.png";
import mockup from "../../images/mockup3.png";
import waveFooter from "../../images/wave-footer.png";
import benefitPrice from '../../images/benefit-price.png';
import benefitTime from '../../images/benefit-time.png';
import benefitRevenue from '../../images/benefit-revenue.png';
import benefitSaveTime from '../../images/benefit-save-time.png';
import benefitTeam from '../../images/benefit-team.png';
import benefitControl from '../../images/benefit-control.png';
import reviewScreenshot from '../../images/screenshot1.png';
import reviewStars from '../../images/review-stars.png';
import kieran from '../../images/kieran.jpg';
import checkMark from '../../images/check-mark.png';
import pricingWarehouse from '../../images/pricing-warehouse.png';
import aboutImages from '../../images/about-images.png';
import xButton from '../../images/x-button.png';
import globeIcon from '../../images/global-icon.png';
import crateIcon from '../../images/crate-icon.png';
import locationIcon from '../../images/location-icon.png';
import customs from '../../images/transland-mockup.png';
import recruit from '../../images/recruit.png';
import xtrails from '../../images/xtrails.png';
import socialjam from '../../images/socialjam.png';
import pharmapro from '../../images/pharmapro.png';
import medlaw from '../../images/medlaw.png';
import unbanx from '../../images/unbanx.png';

import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';

export const HowItWorks = () => {
  const [user, setUser] = useState({});
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  const ITEMS_PER_PAGE = 25;
  const [menuOpen,setMenuOpen] = useState(false);

    const renderMenu = ()=>{
      if(menuOpen){
        return(
          <div className={styles.mobileMenu}>
            <img src={xButton} className={styles.menuXButton} onClick={()=>{setMenuOpen(false)}} />
            <div className={styles.menuItems}>
              <a style={{ textDecoration: 'none' }} href={'./how-it-works'}>
                <div className={styles.menuItem}>
                  Our Portfolio
                </div>
              </a>
              <a style={{ textDecoration: 'none' }} href={'./about-us'}>
                <div className={styles.menuItem}>
                  About Us
                </div>
              </a>
              <a style={{ textDecoration: 'none' }} href={'./contact-us'}>
                <div className={styles.menuItem}>
                  Contact Us
                </div>
              </a>

              <br/>
              <a style={{ textDecoration: 'none' }} href={'./contact-us'}>
              <div className={styles.signupButtonBlue}>
                Book A Call
                <img alt={"Signup button icon."} src={signupIcon} className={styles.signupButtonBlueIcon} />
              </div>
              </a>
            </div>
          </div>
        )
      }
    }

    return(
      <div style={{position:"absolute",top:0,left:0}}>
        <div id="homepage-header" className={styles.header}>
          <a style={{ textDecoration: 'none' }} href={'../'}>
            <img alt={"FreightCart system logo including a parcel icon and the name of the system."} src={logoDark} className={styles.headerLogo} />
          </a>

          <div className={styles.headerMenu}>
            <a style={{ textDecoration: 'none' }} href={'./how-it-works'}>
              <div style={{color:"#0a68ff"}} className={styles.headerMenuItem}>
                Our Portfolio
              </div>
            </a>
            <a style={{ textDecoration: 'none' }} href={'./demos'}>
              <div className={styles.headerMenuItem}>
                Demos
              </div>
            </a>
            <a style={{ textDecoration: 'none' }} href={'./about-us'}>
              <div className={styles.headerMenuItem}>
                About Us
              </div>
            </a>
            <a style={{ textDecoration: 'none' }} href={'./contact-us'}>
              <div className={styles.headerMenuItem}>
                Contact Us
              </div>
            </a>
          </div>

          <div className={styles.headerButtons}>

            <a style={{ textDecoration: 'none' }} href="./contact-us">
            <div className={styles.headerButtonBlue}>
              Get An Estimate
              <img alt={"FreightCart platform icon beside a signup button."} src={signupIcon} className={styles.signupButtonIcon} />
            </div>
            </a>

            <div onClick={()=>{setMenuOpen(true)}} className={styles.headerButtonBlueMenu}>
              Menu
              <img alt={"An icon in the Get A Demo button for our platform."} src={signupIcon} className={styles.signupButtonIcon} />
            </div>
          </div>
        </div>

        <div className={styles.landingContainer}>
          <div className={styles.landingText}>
            <div className={styles.landingTextContent}>
              <h1 className={styles.landingTextHeader}>What Have We Worked On Before?</h1>
              <h2 className={styles.landingTextContentH}>
              View some of our work below. We've worked on projects in logstistics, health, marketing, banking, online sales, communication and much more.
              <br/>We've worked with companies from startups to international enterprises, giving us a wealth of experience.</h2>
              <a style={{ textDecoration: 'none' }} href="./contact-us">
                <div className={styles.signupButtonBlue}>
                  Get In Touch
                  <img alt={"Signup icon in the Get In Touch Button."} src={signupIcon} className={styles.signupButtonBlueIcon} />
                </div>
              </a>
            </div>
          </div>
          <Zoom>
            <div className={styles.landingImageContainer}>
              <img alt={"Mockup image of the FreightCart transport ordering platform."} src={mockup} className={styles.landingImage} />
            </div>
          </Zoom>
        </div>


        <img alt={"Wave image to break up content."} src={waveFooter} className={styles.waveFooter} />

        <div className={styles.infoContainer}>

        <div className={styles.pricingText2}>
          <div className={styles.pricingTextHeader}>Case Study: Unbanx</div>
          <div className={styles.pricingTextSubheader}>Open Banking Data Startup</div><br/><br/>
          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            Third-Party Banking Data Integrations
          </div>

          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            Survey & Email Integrations
          </div>

          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            Secure In-App Transactions
          </div>

          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            Encrypted User Data Vaults
          </div>

          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            Phone Authentication
          </div>

          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            User Data Extraction & Management
          </div>

          <br/>
          <a style={{ textDecoration: 'none' }} href="https://unbanx.me">
            <div style={{marginLeft:"2vw"}} className={styles.signupButtonBlue}>
              See Unbanx
              <img alt={"A signup icon in the signup button."} src={signupIcon} className={styles.signupButtonBlueIcon} />
            </div>
          </a>
      </div>
          <Zoom>
          <div className={styles.landingImageContainer}>
            <img alt={"An image displaying potential forms a company can have to accept files from bookers."} src={unbanx} className={styles.landingImageUnbanx} />
          </div>
          </Zoom>

        </div>

        <img alt={"Wave image to break up content."} src={waveFooter} className={styles.waveFooter} />

        <div className={styles.infoContainer}>

        <div className={styles.pricingText2}>
          <div className={styles.pricingTextHeader}>Case Study: Transland Group</div>
          <div className={styles.pricingTextSubheader}>International Logistics Enterprise</div><br/><br/>
          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            State-Of-The-Art Ordering System
          </div>

          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            Full-Control Backoffice Management
          </div>

          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            Third-Party Provider Integrations
          </div>

          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            Advanced Pricing Algorithms
          </div>

          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            Secure Payment Integration
          </div>

          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            Customer Management System
          </div>

          <br/>
          <a style={{ textDecoration: 'none' }} href="https://translandgroup.com">
            <div style={{marginLeft:"2vw"}} className={styles.signupButtonBlue}>
              See Transland
              <img alt={"A signup icon in the signup button."} src={signupIcon} className={styles.signupButtonBlueIcon} />
            </div>
          </a>
      </div>
          <Zoom>
          <div className={styles.landingImageContainer}>
            <img alt={"An image displaying potential forms a company can have to accept files from bookers."} src={customs} className={styles.landingImageTransland} />
          </div>
          </Zoom>

        </div>

        <img alt={"Wave image to break up content."} src={waveFooter} className={styles.waveFooter} />

        <div className={styles.infoContainer}>

        <div className={styles.pricingText2}>
          <div className={styles.pricingTextHeader}>Case Study: Recruit.ie</div>
          <div className={styles.pricingTextSubheader}>A Top Irish Recruitment Startup</div><br/><br/>
          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            UI/ UX Overhaul
          </div>

          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            Stable iOS & Android Releases
          </div>

          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            Third-Party Payment Integrations
          </div>

          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            Advanced Search & Filtering Algorithms
          </div>

          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            Secure Payment Integration & Subscriptions
          </div>

          <div className={styles.pricingPoint}>
            <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
            Customer Management Backend System
          </div>

          <br/>
          <a style={{ textDecoration: 'none' }} href="https://apps.apple.com/us/app/recruit-ie/id1638680033">
            <div style={{marginLeft:"2vw"}} className={styles.signupButtonBlue}>
              See Recruit.ie
              <img alt={"A signup icon in the signup button."} src={signupIcon} className={styles.signupButtonBlueIcon} />
            </div>
          </a>
      </div>
          <Zoom>
          <div className={styles.landingImageContainer}>
            <img alt={"An image displaying potential forms a company can have to accept files from bookers."} src={recruit} className={styles.landingImageTransland} />
          </div>
          </Zoom>

        </div>

        <img alt={"Wave image to break up content."} src={waveFooter} className={styles.waveFooter} />


        <div className={styles.infoContainer}>


            <Zoom>
            <div className={styles.landingImageContainer}>
              <img alt={"An image displaying potential forms a company can have to accept files from bookers."} src={xtrails} className={styles.landingImageTransland} />
            </div>
            </Zoom>

            <div className={styles.pricingText2}>
              <div className={styles.pricingTextHeader}>Case Study: XTrails</div>
              <div className={styles.pricingTextSubheader}>US & Irish Exploration Startup</div><br/><br/>
              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                UI/ UX Design
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Stable iOS & Android Releases
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                App Store Payment Integrations
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Media Management & Streaming Platform
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                QR Code Scanning System
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Data Management System
              </div>

              <br/>
              <a style={{ textDecoration: 'none' }} href="https://play.google.com/store/apps/details?id=com.xtrailspro">
                <div style={{marginLeft:"2vw"}} className={styles.signupButtonBlue}>
                  See X-Trails
                  <img alt={"A signup icon in the signup button."} src={signupIcon} className={styles.signupButtonBlueIcon} />
                </div>
              </a>
          </div>

        </div>

        <img alt={"Wave image to break up content."} src={waveFooter} className={styles.waveFooter} />


        <div className={styles.infoContainer}>


            <Zoom>
            <div className={styles.landingImageContainer}>
              <img alt={"An image displaying potential forms a company can have to accept files from bookers."} src={socialjam} className={styles.landingImageTransland} />
            </div>
            </Zoom>

            <div className={styles.pricingText2}>
              <div className={styles.pricingTextHeader}>Case Study: SocialJam</div>
              <div className={styles.pricingTextSubheader}>Social Media Management Platform</div><br/><br/>
              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                UI/ UX Design
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Stable Web Application
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Facebook, Instagram, Twitter & Pinterest Integrations
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Media Management
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                User Permissions & Profile Structure
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Sharing & Content Widgets
              </div>

              <br/>
              <a style={{ textDecoration: 'none' }} href="https://my.socialjam.ie">
                <div style={{marginLeft:"2vw"}} className={styles.signupButtonBlue}>
                  See SocialJam
                  <img alt={"A signup icon in the signup button."} src={signupIcon} className={styles.signupButtonBlueIcon} />
                </div>
              </a>
          </div>

        </div>

        <img alt={"Wave image to break up content."} src={waveFooter} className={styles.waveFooter} />

        <div className={styles.infoContainer}>

            <div className={styles.pricingText2}>
              <div className={styles.pricingTextHeader}>Case Study: PharmaPro</div>
              <div className={styles.pricingTextSubheader}>Internal Pharmacy Analytics Platform</div><br/><br/>
              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                UI/ UX Design
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Desktop Application for Mac & Windows
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Third Party Provider Integrations
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Advanced AI Algorithms & OpenAI Integration
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Dynamic Graphs & Performance Analytics
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Employer & Employee User Structure
              </div>

              <br/>
              <a style={{ textDecoration: 'none' }} href="https://pharmapro.ie">
                <div style={{marginLeft:"2vw"}} className={styles.signupButtonBlue}>
                  See PharmaPro
                  <img alt={"A signup icon in the signup button."} src={signupIcon} className={styles.signupButtonBlueIcon} />
                </div>
              </a>
          </div>

          <Zoom>
          <div className={styles.landingImageContainer}>
            <img alt={"An image displaying potential forms a company can have to accept files from bookers."} src={pharmapro} className={styles.landingImageTransland} />
          </div>
          </Zoom>


        </div>

        <img alt={"Wave image to break up content."} src={waveFooter} className={styles.waveFooter} />

        <div className={styles.infoContainer}>


            <Zoom>
            <div className={styles.landingImageContainer}>
              <img alt={"An image displaying potential forms a company can have to accept files from bookers."} src={medlaw} className={styles.landingImageTransland} />
            </div>
            </Zoom>

            <div className={styles.pricingText2}>
              <div className={styles.pricingTextHeader}>Case Study: Medlaw</div>
              <div className={styles.pricingTextSubheader}>Advanced Medic Referral Platform</div><br/><br/>
              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Powerful Referral Management System
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Stable Web Application
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Third Party Integrations & Email
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Dynamic Algorithms For Search
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Medic Permissions & Admin Structure
              </div>

              <div className={styles.pricingPoint}>
                <img alt={"A checkmark image beside a feature of our platform."} src={checkMark} className={styles.pricingPointIcon} />
                Secure Patient File Management
              </div>

              <br/>
              <a style={{ textDecoration: 'none' }} href="https://medlaw.ie">
                <div style={{marginLeft:"2vw"}} className={styles.signupButtonBlue}>
                  See Medlaw
                  <img alt={"A signup icon in the signup button."} src={signupIcon} className={styles.signupButtonBlueIcon} />
                </div>
              </a>
          </div>

        </div>

        <img alt={"Wave image to break up content."} src={waveFooter} className={styles.waveFooter} />



        <div className={styles.figuresContainer}>
          <div className={styles.figure}>
            <div className={styles.figureHeader}>26%</div>
            Average Increase In Revenue
          </div>

          <div className={styles.figure}>
            <div className={styles.figureHeader}>35%</div>
            Higher User Rates
          </div>

          <div className={styles.figure}>
            <div className={styles.figureHeader}>30+</div>
            Mobile & Web Apps Developed
          </div>

          <div className={styles.figure}>
            <div className={styles.figureHeader}>100%</div>
            Client Satisfaction
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.footerPortion}>
            <img alt={"FreightCart platform logo including a parcel icon and the name of the platform."} src={logoDark} className={styles.footerLogo} />
          </div>
          <div className={styles.footerPortion}>
          </div>
          <div className={styles.footerPortion}>
            <a style={{ textDecoration: 'none' }} href="./how-it-works"><div className={styles.footerLink}>Our Portfolio</div></a>
            <a style={{ textDecoration: 'none' }} href="./about-us"><div className={styles.footerLink}>About Us</div></a>
            <a style={{ textDecoration: 'none' }} href="./contact-us"><div className={styles.footerLink}>Contact Us</div></a>
          </div>
          <div className={styles.footerPortionFlex}>
            <div className={styles.flexPadding}></div>
            <div className={styles.footerCopyright}>&copy; Conquer Technologies Ltd. 2023</div>
          </div>
        </div>
        {renderMenu()}
      </div>
    )


}
