import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { logout } from "../../services";
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import {getUserDetails} from '../../services/';
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';
import ReactHtmlParser from 'react-html-parser';

import styles from './styles.module.scss';

import logoDark from "../../images/logo-dark.png";
import signupIcon from "../../images/signup-icon.png";
import mockup from "../../images/mockup2.png";
import waveFooter from "../../images/wave-footer.png";
import benefitPrice from '../../images/benefit-price.png';
import benefitTime from '../../images/benefit-time.png';
import benefitRevenue from '../../images/benefit-revenue.png';
import benefitSaveTime from '../../images/benefit-save-time.png';
import benefitTeam from '../../images/benefit-team.png';
import benefitControl from '../../images/benefit-control.png';
import reviewScreenshot from '../../images/screenshot1.png';
import reviewStars from '../../images/review-stars.png';
import kieran from '../../images/kieran.jpg';
import checkMark from '../../images/check-mark.png';
import pricingWarehouse from '../../images/pricing-warehouse.png';
import glasses from '../../images/glasses.png';
import { db } from '../../services/firebase.js';
import { collection, getDoc, doc } from "firebase/firestore";
import xButton from '../../images/x-button.png';
import globeIcon from '../../images/global-icon.png';
import crateIcon from '../../images/crate-icon.png';
import locationIcon from '../../images/location-icon.png';

import articles from '../articles.js';

export const Article = () => {
  const [user, setUser] = useState({});
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  const {articleId} = useParams();
  const [articleData,setArticleData] = useState({});
  const ITEMS_PER_PAGE = 25;
  const [menuOpen,setMenuOpen] = useState(false);
  const form = useRef();
  const [loading,setLoading] = useState(false);

  const sendMessage = (e)=>{
    e.preventDefault();
    const formData = form.current;
    setLoading(true);

    emailjs.sendForm('service_91jtlma', 'template_hhy6zha', formData, 'h8beUKjFjcEy8tO0m')
      .then((result) => {
            Swal.fire(
          'Message Sent!',
          'We will get back to you within 24 Hours',
          'success'
        )
        document.getElementById("contact-form").style.display = "none";
        setLoading(false);
      }, (error) => {
        Swal.fire(
          'Message Not Sent!',
          'Please Try Again',
          'error'
        )
        console.log(error);
        setLoading(false);
      });

  }

  const renderForm = ()=>{
    if(loading){
      return(
        <div style={{textAlign:"center",alignItems:"center"}}>
          <br/><br/>
          <GridLoader  size={30} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <form ref={form} onSubmit={sendMessage}>
      <input name={"name"} placeholder={"Your Name*"} type="text" className={styles.contactInput} value={contactName} onChange={(event)=>{setContactName(event.target.value)}} />
      <input name={"email"} placeholder={"Your Email*"} type="text" className={styles.contactInput} value={contactEmail} onChange={(event)=>{setContactEmail(event.target.value)}} />
      <textarea name={"message"} placeholder={"How can we help?"} type="text" className={styles.contactInputMessage} value={contactMessage} onChange={(event)=>{setContactMessage(event.target.value)}} />
      <br/><br/>
      <div onClick={sendMessage} className={styles.submitButtonBlue}>
        Send Message
        <img alt={"A signup icon in another signup button."} src={signupIcon} className={styles.submitButtonBlueIcon} />
      </div>
      </form>
    )
  }


    const renderMenu = ()=>{
      if(menuOpen){
        return(
          <div className={styles.mobileMenu}>
            <img src={xButton} className={styles.menuXButton} onClick={()=>{setMenuOpen(false)}} />
            <div className={styles.menuItems}>
              <a style={{ textDecoration: 'none' }} href={'../how-it-works'}>
                <div className={styles.menuItem}>
                  How It Works
                </div>
              </a>
              <a style={{ textDecoration: 'none' }} href={'../about-us'}>
                <div className={styles.menuItem}>
                  About Us
                </div>
              </a>
              <a style={{ textDecoration: 'none' }} href={'../blog'}>
                <div className={styles.menuItem}>
                  Blog
                </div>
              </a>
              <a style={{ textDecoration: 'none' }} href={'../contact-us'}>
                <div className={styles.menuItem}>
                  Contact Us
                </div>
              </a>
                <div className={styles.menuItem}>
                  Use Cases:
                </div>
                  <br/>
                  <a style={{ textDecoration: 'none' }} href={'../use-cases/national-transporters'}>
                  <div className={styles.dropdownMenuItem}>
                    <img className={styles.dropdownIcon} src={crateIcon} />
                    National Transporters
                  </div>
                  </a>
                  <a style={{ textDecoration: 'none' }} href={'../use-cases/local-couriers'}>
                    <div className={styles.dropdownMenuItem}>
                      <img className={styles.dropdownIcon} src={locationIcon} />
                      Local Couriers
                    </div>
                  </a>
                  <a style={{ textDecoration: 'none' }} href={'../use-cases/international-freight'}>
                    <div className={styles.dropdownMenuItem}>
                      <img className={styles.dropdownIcon} src={globeIcon} />
                      International Freight
                    </div>
                  </a>


              <br/>
              <a style={{ textDecoration: 'none' }} href={'../contact-us'}>
              <div className={styles.signupButtonBlue}>
                Book A Demo
                <img alt={"Signup button icon."} src={signupIcon} className={styles.signupButtonBlueIcon} />
              </div>
              </a>
            </div>
          </div>
        )
      }
    }

  const getDateAsString = (time)=>{
    let date = new Date(time);

    return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear();
  }

  useEffect(async()=>{

    for(let index = 0;index < articles.length;index++){
      if(articleId == articles[index].id){
        document.title = articles[index].title;
        setArticleData(articles[index]);
        document.getElementById("article-content").innerHTML = articles[index].content;
      }
    }

  },[])

    return(
      <div style={{position:"absolute",top:0,left:0}}>
        <div id="homepage-header" className={styles.header}>
          <a style={{ textDecoration: 'none' }} href="../../">
            <img src={logoDark} className={styles.headerLogo} />
          </a>
          <div className={styles.headerMenu}>
            <a style={{ textDecoration: 'none' }} href={'../how-it-works'}>
              <div className={styles.headerMenuItem}>
                How It Works
              </div>
            </a>
            <a style={{ textDecoration: 'none' }} href={'../about-us'}>
              <div className={styles.headerMenuItem}>
                About Us
              </div>
            </a>
            <div style={{color:"#0a68ff"}} className={styles.headerMenuItem}>
              Blog
            </div>
            <a style={{ textDecoration: 'none' }} href={'../contact-us'}>
              <div className={styles.headerMenuItem}>
                Contact Us
              </div>
            </a>
          </div>

          <div className={styles.headerButtons}>
            <a href="../contact-us">
            <div className={styles.headerButtonBlue}>
              Get A Demo
              <img src={signupIcon} className={styles.signupButtonIcon} />
            </div>
            </a>

            <div onClick={()=>{setMenuOpen(true)}} className={styles.headerButtonBlueMenu}>
              Menu
              <img alt={"An icon in the Get A Demo button for our platform."} src={signupIcon} className={styles.signupButtonIcon} />
            </div>
          </div>
        </div>

        <div className={styles.articleDisplay}>
          <div alt={"Image of freight software blog post."} style={{backgroundImage:"url("+articleData.image+")"}} className={styles.articleHeaderImage} />
          <div className={styles.articlePadding}>
            <h1 className={styles.articleTitle}>{articleData.title}</h1>
            <div className={styles.articleDate}>Published By Gareth Power On {articleData.date}</div><br/>
            <h2 className={styles.articleSubtitle}>{articleData.subtitle}</h2>
            <div className={styles.articleContent} id={"article-content"}>
            </div>
          </div>
        </div>

        <div className={styles.contactContainer}>
          <div className={styles.contactForm}>
            <div className={styles.contactHeader}>Looking For A Powerful Online Logistics Booking System?</div>
            <div className={styles.contactSubheader}>Get In Touch With Us, There Are No Setup Fees</div>
            {renderForm()}
          </div>
        </div>


        <div className={styles.footer}>
          <div className={styles.footerPortion}>
            <img src={logoDark} className={styles.footerLogo} />
          </div>
          <div className={styles.footerPortion}>
            <a style={{ textDecoration: 'none' }} href="../contact-us"><div className={styles.footerLink}>Sign Up</div></a>
            <a style={{ textDecoration: 'none' }} href=""><div className={styles.footerLink}>Login</div></a>
            <a style={{ textDecoration: 'none' }} href=""><div className={styles.footerLink}>Terms Of Service</div></a>
            <a style={{ textDecoration: 'none' }} href="../contact-us"><div className={styles.footerLink}>Support</div></a>
          </div>
          <div className={styles.footerPortion}>
            <a style={{ textDecoration: 'none' }} href="../how-it-works"><div className={styles.footerLink}>How It Works</div></a>
            <a style={{ textDecoration: 'none' }} href="../about-us"><div className={styles.footerLink}>About Us</div></a>
            <a style={{ textDecoration: 'none' }} href="../contact-us"><div className={styles.footerLink}>Contact Us</div></a>
            <a style={{ textDecoration: 'none' }} href=""><div className={styles.footerLink}>Privacy Policy</div></a>
          </div>
          <div className={styles.footerPortionFlex}>
            <div className={styles.flexPadding}></div>
            <div className={styles.footerCopyright}>&copy; Gareth Power & Frank Olden 2023</div>
          </div>
        </div>
        {renderMenu()}
      </div>
    )


}
