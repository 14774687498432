import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { logout } from "../../services";
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import {getUserDetails} from '../../services/';

import styles from './styles.module.scss';

import logoDark from "../../images/logo-dark.png";
import signupIcon from "../../images/signup-icon.png";
import mockup from "../../images/mockup2.png";
import waveFooter from "../../images/wave-footer.png";
import benefitPrice from '../../images/benefit-price.png';
import benefitTime from '../../images/benefit-time.png';
import benefitRevenue from '../../images/benefit-revenue.png';
import benefitSaveTime from '../../images/benefit-save-time.png';
import benefitTeam from '../../images/benefit-team.png';
import benefitControl from '../../images/benefit-control.png';
import reviewScreenshot from '../../images/screenshot1.png';
import reviewStars from '../../images/review-stars.png';
import kieran from '../../images/kieran.jpg';
import checkMark from '../../images/check-mark.png';
import pricingWarehouse from '../../images/pricing-warehouse.png';
import glasses from '../../images/glasses.png';
import { db } from '../../services/firebase.js';
import { collection, getDocs } from "firebase/firestore";

import articles from '../articles.js';

export const Blog = () => {
  const [user, setUser] = useState({});
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  const ITEMS_PER_PAGE = 25;

    /* window.addEventListener('scroll', ()=>{
      if(window.scrollY > 10){
        document.getElementById("homepage-header").style.background = "white";
      }else{
        document.getElementById("homepage-header").style.background = "#00000000";
      }
    }); */

  const getDateAsString = (time)=>{
    let date = new Date(time);

    return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear();
  }

  /* useEffect(async()=>{
    const tmp = await getDocs(collection(db,"articles"));
    let articleArray = [];
    tmp.forEach((doc) => {
      articleArray.push({
        ...doc.data(), //spread operator
        id: doc.id, // `id` given to us by Firebase
      });
    });
    setArticles(articleArray);
  },[articles]) */

    return(
      <div style={{position:"absolute",top:0,left:0}}>
        <div id="homepage-header" className={styles.header}>
          <a style={{ textDecoration: 'none' }} href="../">
            <img src={logoDark} className={styles.headerLogo} />
          </a>
          <div className={styles.headerMenu}>
            <a style={{ textDecoration: 'none' }} href={'./how-it-works'}>
              <div className={styles.headerMenuItem}>
                How It Works
              </div>
            </a>
            <a style={{ textDecoration: 'none' }} href={'./about-us'}>
              <div className={styles.headerMenuItem}>
                About Us
              </div>
            </a>
            <div style={{color:"#0a68ff"}} className={styles.headerMenuItem}>
              Blog
            </div>
            <a style={{ textDecoration: 'none' }} href={'./contact-us'}>
              <div className={styles.headerMenuItem}>
                Contact Us
              </div>
            </a>
          </div>

          <div className={styles.headerButtons}>
            <div className={styles.headerButtonGrey}>Log In</div>
            <div className={styles.headerButtonBlue}>
              Sign Up
              <img src={signupIcon} className={styles.signupButtonIcon} />
            </div>
          </div>
        </div>

        <div className={styles.blogBanner}>
          <div className={styles.blogBannerImage} style={{backgroundImage:"url("+articles[0]?.image+")"}} />
          <div className={styles.blogBannerText}>
            <div className={styles.blogBannerTitle}>{articles[0]?.title}</div>
            <div className={styles.blogBannerDate}>Written On {articles[0]?.date}</div>
            <div className={styles.blogBannerBlurb}>{articles[0]?.blurb}</div>
            <div onClick={(event)=>{document.location = "./article/"+articles[0]?.id}} className={styles.readButtonBlue}>
              Read More
              <img src={glasses} className={styles.readButtonBlueIcon} />
            </div>
          </div>
        </div>

        <div className={styles.blogArticles}>
          {
            articles.map((article,articleIndex)=>{
              if(articleIndex == 0 || articleIndex > 15) return;
              return(
                <div className={styles.article}>
                  <div className={styles.articleImage} style={{backgroundImage:"url("+article.image+")"}} />
                  <div className={styles.articleText}>
                    <div className={styles.articleTitle}>{article.title}</div>
                    <div className={styles.articleDate}>Written On {article.date}</div>
                    <div className={styles.articleBlurb}>{article.blurb}</div>
                  </div>
                  <div onClick={(event)=>{document.location = "./article/"+article.id}} className={styles.readButtonBlue}>
                    Read More
                    <img src={glasses} className={styles.readButtonBlueIcon} />
                  </div>
                </div>
              )
            })
          }
        </div>

        <div className={styles.contactContainer}>
          <div className={styles.contactForm}>
            <div className={styles.contactHeader}>Looking For A Powerful AI-Driven Pharmacy System?</div>
            <div className={styles.contactSubheader}>Get In Touch With Us, There Are No Setup Fees</div>
            <input placeholder={"Your Name*"} type="text" className={styles.contactInput} value={contactName} onChange={(event)=>{setContactName(event.target.value)}} />
            <input placeholder={"Your Email*"} type="text" className={styles.contactInput} value={contactEmail} onChange={(event)=>{setContactEmail(event.target.value)}} />
            <textarea placeholder={"How can we help?"} type="text" className={styles.contactInputMessage} value={contactMessage} onChange={(event)=>{setContactMessage(event.target.value)}} />
            <br/><br/>
            <div className={styles.submitButtonBlue}>
              Send Message
              <img src={signupIcon} className={styles.submitButtonBlueIcon} />
            </div>
          </div>
        </div>

        <div className={styles.figuresContainer}>
          <div className={styles.figure}>
            <div className={styles.figureHeader}>26%</div>
            Average Increase In Revenue
          </div>

          <div className={styles.figure}>
            <div className={styles.figureHeader}>35%</div>
            Increase In Average Customer Retention
          </div>

          <div className={styles.figure}>
            <div className={styles.figureHeader}>37%</div>
            Fewer Dispensing Errors on Average
          </div>

          <div className={styles.figure}>
            <div className={styles.figureHeader}>53%</div>
            Faster Dispensing Times
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.footerPortion}>
            <img src={logoDark} className={styles.footerLogo} />
          </div>
          <div className={styles.footerPortion}>
            <a style={{ textDecoration: 'none' }} href="./contact-us"><div className={styles.footerLink}>Sign Up</div></a>
            <a style={{ textDecoration: 'none' }} href=""><div className={styles.footerLink}>Login</div></a>
            <a style={{ textDecoration: 'none' }} href=""><div className={styles.footerLink}>Terms Of Service</div></a>
            <a style={{ textDecoration: 'none' }} href="./contact-us"><div className={styles.footerLink}>Support</div></a>
          </div>
          <div className={styles.footerPortion}>
            <a style={{ textDecoration: 'none' }} href="./how-it-works"><div className={styles.footerLink}>How It Works</div></a>
            <a style={{ textDecoration: 'none' }} href="./about-us"><div className={styles.footerLink}>About Us</div></a>
            <a style={{ textDecoration: 'none' }} href="./contact-us"><div className={styles.footerLink}>Contact Us</div></a>
            <a style={{ textDecoration: 'none' }} href=""><div className={styles.footerLink}>Privacy Policy</div></a>
          </div>
          <div className={styles.footerPortionFlex}>
            <div className={styles.flexPadding}></div>
            <div className={styles.footerCopyright}>&copy; Gareth Power & Frank Olden 2023</div>
          </div>
        </div>
      </div>
    )


}
